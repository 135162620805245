
import {defineComponent, reactive, toRefs, onBeforeMount, onMounted,ref} from 'vue'
import keynoteDetail from "@/components/page/keynoteDetail.vue";
import axios from '@/api/http.js';
import {ElLoading} from 'element-plus';

import moment from "moment";

export default defineComponent({
  name: '',
  components: {
    keynoteDetail,
    ElLoading
  },
  setup() {
    const contentDiv = ref(null);
    let swiper_options = reactive({
      // slidesPerView: 1,
      // autoplay: {
      //   delay: 3000,
      //   disableOnInteraction: false
      // },
      // loop: true,
      speed: 300,
      // pagination: {
      //   clickable: true
      // },
      // navigation: {
      //   nextElRef: ".swiper-button-next",
      //   prevElRef: ".swiper-button-prev",
      // },
      slidesPerView: 'auto',
      spaceBetween: 15,
      slideToClickedSlide: true,
      centeredSlides: false,
      // initialSlide: 0,
      observer: true,//observer=true//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//observeParents=true//修改swiper的父元素时，自动初始化swiper
      // breakpoints: {
      //   1024: {
      //     slidesPerView: 7,
      //     spaceBetween: 15,
      //   },
      //   1400: {
      //     slidesPerView: 10,
      //     spaceBetween: 15,
      //   },
      //   1680: {
      //     slidesPerView: 13,
      //     spaceBetween: 15,
      //   },
      //   1920: {
      //     slidesPerView: 15,
      //     spaceBetween: 0,
      //   },
      // },
    })
    const data = reactive({
      dateBtns: [],//日期
      keynotes: [],//keynotes详细信息
      showKeynote: false,
      title: 'Keynote',
    })
    // 获取离当天最近的一天
    const getClosetDay = (arr) => {
      const lowPrice = Math.min.apply(Math, arr.map(item => item.numAbs))
      return {
        data: JSON.parse(JSON.stringify(arr.find(item => lowPrice === item.numAbs))),
        index: arr.findIndex(item => lowPrice === item.numAbs)
      }
    }

    // 获取日期
    const agendasDateTime = () => {
      axios.request({
        method: 'POST',
        url: '/virtual/agendasDateTime?timeZone='+Intl.DateTimeFormat().resolvedOptions().timeZone,
        data: {
          meetingId: 'mg9lr5rzd62to2hcidef11id',
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      }).then(res => {
        let current = new Date;
        let currentUnix = moment(current).startOf('day').format("x");
        console.log("==== current=====", current)
        console.log("==== currentUnix=====", currentUnix)
        let dateArr = res.data.data;
        let arr = [];
        dateArr.map(item => {
          let obj = {};
          let month = moment(item).format("MMMM");
          let day = moment(item).format("ddd");
          let date = moment(item).format("D");
          let dateUnix = moment(item).format("x");
          let num = Number(currentUnix) - Number(dateUnix);//时间戳差值
          let numAbs = Math.abs(num);//时间戳差值绝对值
          obj["month"] = month;
          obj["day"] = day;
          obj["date"] = date;
          obj["fullDate"] = item;
          obj["actived"] = false;
          obj["dateUnix"] = dateUnix;
          obj["num"] = num;
          obj["numAbs"] = numAbs;
          arr.push(obj);
        })
        data.dateBtns = arr;
        let cloestDay = getClosetDay(data.dateBtns);//离当天最近的一天
        console.log("====cloestDay =====", cloestDay)
        clickDateBtn(cloestDay.data);
      }).catch(err => {
        console.log(err);
      })
    }
    // 选择日期
    const clickDateBtn = (date) => {
      console.log("==== date!!!!!!!!=====", date)
      // console.log(contentDiv.value.scrollHeight,'3333',contentDiv.value.scrollTop)
      contentDiv.value.scrollTop = 0;
      let length = data.dateBtns.length;
      for (let i = 0; i < length; i++) {
        let item = data.dateBtns[i];
        if (item.fullDate == date.fullDate) {
          item["actived"] = true;
          agendasByDate(date.fullDate);
          data.showKeynote = true;
        } else {
          item["actived"] = false;
        }
      }
    }
    // 获取当前日期的keynote
    const agendasByDate = (date: String) => {
      console.log("==== date~~~~~~~~~~~~~~~~=====", date)
     const loading = ElLoading.service({
        lock: true,
      })
      axios.request({
        method: 'POST',
        url: '/virtual/agendasByDate?timeZone='+Intl.DateTimeFormat().resolvedOptions().timeZone,
        data: {
          meetingId: "mg9lr5rzd62to2hcidef11id",
          dateTime: date,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      }).then(res => {
        loading.close();
        console.log('res.data.data!!!!!!!!!!!!!!!!!!!!!:', res.data.data);
        data.keynotes = res.data.data;
      }).catch(err => {
        loading.close();
        console.log(err);
      })
    }
    let clientHeight = ref('') //浏览器可视区域高度


    agendasDateTime();
    onBeforeMount(() => {
      console.log('2.组件挂载页面之前执行----onBeforeMount')
      
      let client = localStorage.getItem("eventClient");
      if(client == "Aruba"){
        data.title = 'Keynotes';
      }else{
        data.title = 'AGENDA';
      }
    })
    onMounted(() => {
      console.log('3.-组件挂载到页面之后执行-------onMounted')
      // clientHeight.value = `${document.documentElement.clientHeight}` //获取浏览器可视区域高度
      // console.log(window.getComputedStyle(contentDiv).height)

    })
    const refData = toRefs(data);
    return {
      clickDateBtn,
      ...refData,
      swiper_options,
      contentDiv,
    }

  }
});
