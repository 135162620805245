import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c657e20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "keyNotes pagePadding" }
const _hoisted_2 = { class: "pageTitle textColor" }
const _hoisted_3 = { style: {"margin-bottom":"30px"} }
const _hoisted_4 = { ref: "contentDiv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_keynoteDetail = _resolveComponent("keynoteDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_swiper, {
          "slides-per-view": _ctx.swiper_options.slidesPerView,
          observer: _ctx.swiper_options.observer,
          observeParents: _ctx.swiper_options.observeParents,
          navigation: _ctx.swiper_options.navigation,
          breakpoints: _ctx.swiper_options.breakpoints
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateBtns, (item) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                class: _normalizeClass(["dateBtn", {'actived':item.actived}]),
                onClick: ($event: any) => (_ctx.clickDateBtn(item))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(item.day) + " , " + _toDisplayString(item.month) + " " + _toDisplayString(item.date), 1)
                ]),
                _: 2
              }, 1032, ["class", "onClick"]))
            }), 256))
          ]),
          _: 1
        }, 8, ["slides-per-view", "observer", "observeParents", "navigation", "breakpoints"])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keynotes, (item) => {
          return (_openBlock(), _createBlock(_component_keynoteDetail, { data: item }, null, 8, ["data"]))
        }), 256))
      ], 512), [
        [_vShow, _ctx.showKeynote]
      ])
    ])
  ]))
}